// import { Carousel } from "react-responsive-carousel";
import "../../App.css";
// import screen1 from "./screen1.png";
// import screen2 from "./screen2.png";
import icon from "./ic_launcher.png";

export function MyUnitConversionSupport() {
  return (
    <div className="App-header">
      <header className="Page-title">
        <div>My Unit Conversion Support</div>
      </header>
      <img src={icon} alt="Icon" style={{ margin: "20px" }}/>
      <p className="Product-long-description">      
        If you need to support with My Unit Conversion you can email 
        <p>
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <a href="mailto:support@putrdiparrot.com?subject=My Unit Conversion" style={{color: 'white'}}>Support Email</a>
        </div>
        </p>
      </p>
    </div>
  );
}
