// import { Carousel } from "react-responsive-carousel";
import "../../App.css";
// import screen1 from "./screen1.png";
// import screen2 from "./screen2.png";
import icon from "./ic_launcher.png";
import google from "./googleplay153x46.png";
import fire from "./fire153x46.png";
import applestore from "./applestore.svg";
import msstore from "./MS_864X312.svg";
import { Link } from "react-router-dom";

export function MyUnitConversion() {
  return (
    <div className="App-header">
      <header className="Page-title" style={{marginTop: "10px"}}>
        <div>My Unit Conversion</div>
      </header>
      <img src={icon} alt="Icon" style={{ margin: "20px" }}/>
      <div className="Product-short-description">
        My Unit Conversion is a simple unit of measure conversion tool.
      </div>
      <p className="Product-long-description">     
      <p>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <a href='https://play.google.com/store/apps/details?id=com.putridparrot.MyUnitConversion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src={google}/></a>
        &nbsp;&nbsp;
        <a href='https://apps.apple.com/app/my-unit-conversion/id1600275661'><img alt='Download on the App Store' src={applestore}/></a>
        &nbsp;&nbsp;
        <a href='https://www.amazon.co.uk/MTBSOFTWARE-LIMITED-My-Unit-Conversion/dp/B09RTBBGNM/'><img alt='Download on the Amazon Store' src={fire}/></a>
        &nbsp;&nbsp;
        <a href='https://www.microsoft.com/en-gb/p/my-unit-conversion/9nk6ctdn0l2l?rtc=1&activetab=pivot:overviewtab'><img alt='Download on the Microsoft Store' src={msstore}/></a>
      </div>
      </p>
      <div> 
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Link className="Product-privacy-link" to="/myunitconversion/privacy" style={{cursor: 'pointer'}}>Privacy Policy</Link>&nbsp;|&nbsp;<Link className="Product-support-link" to="/myunitconversion/support" style={{cursor: 'pointer'}}>Support</Link>
      </div>
      </div>
        <p>
          The application has been designed to do a single job, which is to
          convert one unit to another within a selected category.
        </p>
        <p>
          Currently supported categories are
          <ul>
            <li>Angle</li>
            <li>Area</li>
            <li>Data Storage</li>
            <li>Data Transfer Rate</li>
            <li>Energy</li>
            <li>Force</li>
            <li>Frequency</li>
            <li>Fuel Economy</li>
            <li>Length/Distance</li>
            <li>Luminous Energy</li>
            <li>Magnetomotive Force</li>
            <li>Mass/Weight</li>
            <li>Pressure</li>
            <li>Speed</li>
            <li>Temperature</li>
            <li>Time</li>
            <li>Volume</li>
          </ul>
        </p>
      </p>
      {/* <div style={{ verticalAlign: "center" }}>
        <Carousel>
          <div>
            <img src={screen1} alt="Category Screen" />
            <p className="legend">Category Screen</p>
          </div>
          <div>
            <img src={screen2} alt="Editor Screen" />
            <p className="legend">Editor Screen</p>
          </div>
        </Carousel>
      </div> */}
    </div>
  );
}
