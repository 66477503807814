import "../../App.css";

export function More() {
  return (
    <div className="Page">
      <header className="Page-title">
        <div style={{ margin: "20px" }}>More to come in the future...</div>
      </header>
    </div>
  );
}
