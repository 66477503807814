import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Home } from './Pages/Home/Home';
import { More } from './Pages/More/More';
import { MyUnitConversion } from './Pages/MyUnitConversion/MyUnitConversion';
import { MyUnitConversionPrivacy } from './Pages/MyUnitConversion/MyUnitConversionPrivacy';
import { MyUnitConversionSupport } from "./Pages/MyUnitConversion/MyUnitConversionSupport";
import NotFound from './Pages/NotFound/NotFound';

function App() {
  return (
    <Router>        
      <Switch>
        <Route path="/myunitconversion/support">
          <MyUnitConversionSupport />
        </Route>
        <Route path="/myunitconversion/privacy">
          <MyUnitConversionPrivacy />
        </Route>
        <Route path="/myunitconversion">
          <MyUnitConversion />
        </Route>
        <Route path="/more">
          <More />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
