import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import "../../App.css";
import myUnitConversion from "../MyUnitConversion/launcher.png";
import more from "../More/launcher.png";
import twitter from "./Twitter.png";
import email from "./Email.png";

export function Home() {

  const history = useHistory();
  const myUnitConversionClick = () => history.push('/myunitconversion');
  const moreClick = () => history.push('/more');

  return (
    <div>
      <header className="App-header">
        <div style={{ marginBottom: "20px"}}>Welcome to Putrid Parrot Apps</div>
        <Carousel>
            <div onClick={myUnitConversionClick} style={{cursor: 'pointer'}}>
              <div  >
              <img src={myUnitConversion} alt="My Unit Conversion" />
              </div>
              <p className="legend">My Unit Conversion</p>
            </div>
            <div onClick={moreClick} style={{cursor: 'pointer'}}>
              <img src={more} alt="More to come" />
              <p className="legend">More to come</p>
            </div>
        </Carousel>
        <div>
          <div style={{marginBottom: "20px"}}>
            <a href="http://twitter.com/putridparrot" >
              <img src={twitter} alt="Twitter" style={{margin: "5px"}} />
            </a>
            <a href="mailto:support@putridparrot.com" >
              <img src={email} alt="Email" style={{margin: "5px"}} />
            </a>
        </div>
      </div>
      </header>
    </div>
  );
}
