import React from 'react';
import { Link } from 'react-router-dom';
import "../../App.css";

const NotFound = () => (
    <div className="App-header">
      <header className="Page-title" style={{margin: "10px"}}>
        <div>Page not found</div>
      </header>
      <Link to="/" className="Page-title">
        Go Home
      </Link>
  </div>
);

export default NotFound;